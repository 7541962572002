<template>
  <b-card>
    <validation-observer ref="userDetailsRules">
          <b-form @submit.prevent="formSubmitted">
            <b-row>

              <b-col
                  cols="12"
                  class="mb-2"
              >
                <h5 class="mb-0">
                  Account Details
                </h5>
                <small class="text-muted">
                  Update User Information.
                </small>
              </b-col>
              <b-col md="6">

                <b-form-group
                    label="First Name"
                    label-for="fh-name"

                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|alpha"
                      name="First Name"
                  >
                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-name"
                          placeholder="Karim"
                          v-model="modify.first_name"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Last Name"
                    label-for="fh-username"
                >

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-username"
                        placeholder="Abdullah"
                        v-model="modify.last_name"
                    />
                  </b-input-group>

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Email"
                    label-for="fh-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|email"
                      name="Email"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>

                      <b-form-input
                          id="fh-email"
                          type="email"
                          placeholder="Enter a valid Email"
                          v-model="modify.email"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {mapState} from "vuex";

import { required, email } from '@validations'

import {
  BRow,
    BCard,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState('role', ['roles',"rolesError","rolesLoading"]),
    ...mapState('userManagement', ['user',"userError","userLoading"]),
  },
  data() {
    return {
      modify:{
        first_name: undefined,
        last_name: undefined,
        email:undefined,
        password:"",
      },
      confirm_password:undefined,
      role:undefined,


    }
  },
  watch: {

    roles(newValue, oldValue) {
      if (newValue) {

      }
    }
  },
  created() {
    this.$store.dispatch('userManagement/fetchUserInfo').then(result=>{
      this.modify.first_name=this.user.first_name
      this.modify.last_name=this.user.last_name
      this.modify.email=this.user.email
    })
  },
  methods: {

    async formSubmitted() {
      return new Promise((resolve, reject) => {
        this.$refs.userDetailsRules.validate().then(async success => {
          if (success) {
            await  this.$store.dispatch('userManagement/userUpdate',this.modify).then(result=>{
              if(Array.isArray(result)){
                result.forEach(e=>{
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-end',
                    props: {
                      title: e.field+' Error',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text:e.message ,
                    },
                  })
                })
              }else{
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: result.code==400?'Error':`Success`,
                    icon: result.code==400?'ThumbsDownIcon':'ThumbsUpIcon',
                    variant: result.code==400?'danger':'success',
                    text: result.message,
                  },
                })
              }


            })
          } else {
            reject()
          }
        })
      })

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
